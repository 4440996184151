



















import Vuex from 'vuex'
export default
  name: 'list'
  data: ->
    items: []
  computed: Object.assign(
    sortedItems:  ->
      if @items?
        @items.slice().sort (a, b) ->
          parseInt(a.list_fields['other.house']) - parseInt(b.list_fields['other.house'])
      else []
    Vuex.mapState ['amount', 'api_url']
  )
  mounted: ->
    @loadList()
  methods:
    loadList: ->
      @axios.get(@api_url)
        .then (res) => @items = res.data.race.heats
        .catch (e) -> console.log(e)

